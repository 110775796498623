
import { defineComponent } from 'vue';
import DlHead from '@/views/DlHead.vue'; // @ is an alias to /src
export default defineComponent({
  name: 'App',
	mounted(){
		//此方法刷新页面时也会执行
		window.addEventListener('beforeunload',()=>{
			localStorage.removeItem('tk');
		});
	},
	components:{
		DlHead,
	},
});
