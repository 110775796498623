import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DlNodeView from '../views/DlNodeView.vue'
import DlIndexView from '../views/DlIndexView.vue'
import DlUploadView from '../views/DlUploadView.vue'
import LoginView from '../views/LoginView.vue'
import DlTestPageView from '../views/DlTestPageView.vue'
import DlSpView from '../views/DlSpView.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: DlIndexView
  },
  {
    path: '/node/:userId?/:nf?/',
    name: 'node',
		props: true,
    component: DlNodeView
  },
	{
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/upload',
    name: 'upload',
    component: DlUploadView
  },
  {
    path: '/testpage',
    name: 'testpage',
    component: DlTestPageView
  },
  {
    path: '/sppage',
    name: 'sppage',
    component: DlSpView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
				document.title = '代理监控';
        const token = localStorage.getItem("tk");
        if(token || to.path === "/login"){
                next();
        }else{
                //next();
                next("/login")
        }

})

export default router
