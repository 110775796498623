
import axios from 'axios';
import { defineComponent } from 'vue';
import { Edit, Platform, Delete, CirclePlus, Remove } from "@element-plus/icons-vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
	name: 'LoginView',
	data(){
		return{
			hp:"",
			name:"",
			pw:"",
			area:"",
			currentPage:1,
			pageSize:100,
			totalnode:0,
			open_ssh:true,
			close_ssh:false,
			nodelist:[]
		}
	},
	created(){
			console.log("create.......");
			this.getdata()
	},
	methods:{
		closessh(){
			axios.post("/api/openssh/off",{}).then((res) => {
				if(res.data['ret'] === 0){
					this.getdata()
					ElMessage({
						showClose: true,
						message: '后台服务开启成功',
						type: 'info',
					})
				}else{
					ElMessage({
						showClose: true,
						message: '开启失败',
						type: 'error',
					})
				}
			}).catch(error => {
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			})
		},
		openssh(){
			axios.post("/api/openssh/on",{}).then((res) => {
				if(res.data['ret'] === 0){
					this.getdata()
					ElMessage({
						showClose: true,
						message: '后台服务关闭成功',
						type: 'info',
					})
				}else{
					ElMessage({
						showClose: true,
						message: '关闭失败',
						type: 'error',
					})
				}
			}).catch(error => {
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			})
		},

		login(){
			axios.post("/api/ls/add",{"host_p":this.hp,"pw":this.pw,"name":this.name,"bz":this.area}).then((res) => {
				if(res.data['ret'] === 0){
					this.getdata()
					ElMessage({
						showClose: true,
						message: '添加成功',
						type: 'info',
					})
				}else{
					ElMessage({
						showClose: true,
						message: '添加失败',
						type: 'error',
					})
				}
			}).catch(error => {
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			})
		},
		delssh(id:number){
			axios.post("/api/ls/del/"+id).then((res) => {
				if(res.data['ret'] === 0){
					this.getdata();
					ElMessage({
						showClose: true,
						message: '删除成功',
						type: 'info',
					})
				}else{
					ElMessage({
						showClose: true,
						message: '添加失败',
						type: 'error',
					})
				}
			}).catch(error => {
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			})
		},

		gossh(host:string,port:string,un:string,pw:string){
			var url = "/api/shell?host="+host+"&port="+port+"&un="+un+"&pw="+encodeURIComponent(pw)+"&name=Unkown&from=test"
			console.log(url);
			//window.open("/api/shell?host="+host+"&port="+port+"&un="+un+"&pw="+pw+"&name=Unkown&from=test")
			window.open(url);
		},
		getssh(host:string,port:string,un:string,pw:string){
			ElMessage("sshpass -p '"+pw+"' ssh -o StrictHostKeyChecking=no -p "+port+" "+un+"@"+host+"")
		},
		getdata(){
			console.log("go!!!!");
			axios.post("/api/openssh/test",{}).then((res) => {
				if (res.data["ret"] === 0) {
					if(res.data["message"] === "ok"){
						console.log("get ok!!!!");
						this.open_ssh = false;
						this.close_ssh = true;
					}else{
						console.log("get false!!!!");
						this.close_ssh = false;
						this.open_ssh = true;
					}
				}else{
					ElMessage({
					showClose: true,
					message: '数据出错',
					type: 'error',
					})
				}
			}).catch(error => {
				console.log(error);
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			});

			axios.post("/api/ls/list",{"tk":localStorage.getItem("tk")}).then((res) => {
				if (res.data["ret"] === 0) {
					this.nodelist = res.data["nodelist"];
					this.totalnode = this.nodelist.length;
				}else{
					ElMessage({
					showClose: true,
					message: '数据出错',
					type: 'error',
					})
				}
			}).catch(error => {
				console.log(error);
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			});
		},

	}
});
