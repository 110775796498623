
import { defineComponent } from 'vue';
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import {
	DocumentChecked,
	Check,
} from '@element-plus/icons-vue'

export default defineComponent({
  name: 'DlIndexView',
	data(){
		return{
			activeNames:["3","6"],
			offnum:0,
			onnum:0,
			errnum:0,
			mqtterrnum:0,
			unknow:0,
			hsqnum:"未初始化列表",
			lyl:{},
			errl:{},
			uuidl:{},
			mqtterrl:{},
			dyl:{},
			hmjlcbl:{},
			hmjlhyl:{},
			hmjljkl:{},
			hmjlmyl:{},
            hmjlcbnum:0,
            hmjlhynum:0,
            hmjljknum:0,
            hmjlmynum:0,
			mjl:{},
			allnum:0,
			reftime:"",
		}
  },
	created(){
			console.log("create.......");
			this.getoffline()
			this.getmj()
	},
	methods:{
		mmtest(vv: string, k: string){
			return "node/"+vv+"/"+k
		},
		gossh(v: string ){
			window.open("/api/shell?"+v)
        },
		mmgo(vv: string, k: string){
			console.log("click......." + vv + " k " +k)
			console.log("================")
			console.log(this.mjl)
			console.log("======mid========")
			console.log(Object.values(this.mjl)[0])
			//console.log(Object.values(this.mjl)[0].mj)
			console.log("======end========")
			this.$router.push({name:"node",params: { userId: vv, nf: k }})
		},
		repairgo(ly:string,area:string,mj:string,_id:number){
			console.log("1111id....")
			console.log(_id)
			console.log(Object.values(this.mjl)[_id])
			axios.post("/api/allrepair?mj="+mj,{"tk":localStorage.getItem("tk")}).then((res) => {
				if (res.data["ret"] === 0) {
					//console.log(this.mjl(_id))
					console.log(Object.values(this.mjl)[_id])
				}else if (res.data["ret"] === -99) {
					localStorage.removeItem('tk');
					this.$router.push("/login")
				}else{
					console.log("1111id....")
					Object.values(this.mjl)[_id] = 50;
					ElMessage({
					showClose: true,
					message: res.data['msg'],
					type: 'error',
					})
				}

			}).catch(error => {
				console.log(error);
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			});
		},
		getmj(){
			console.log("go!!!!");
			axios.post("/api/getmjlist",{"tk":localStorage.getItem("tk")}).then((res) => {
				if (res.data["ret"] === 0) {
					this.mjl = res.data["mj"]
				}else if (res.data["ret"] === -99) {
					localStorage.removeItem('tk');
					this.$router.push("/login")
				}else{
					ElMessage({
					showClose: true,
					message: res.data['msg'],
					type: 'error',
					})
				}
			}).catch(error => {
				console.log(error);
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			});
		},

		getoffline(){
			console.log("go!!!!");
			axios.post("/api/getindex",{"tk":localStorage.getItem("tk")}).then((res) => {
				if (res.data["ret"] === 0) {
					this.offnum = res.data["offline"]
					this.onnum = res.data["online"]
					this.errnum = res.data["errnum"]
					this.mqtterrnum = res.data["mqtterrnum"]
					this.lyl = res.data["lyl"]
					this.errl = res.data["errl"]
					this.uuidl = res.data["uuidl"]
					this.hsqnum = "未初始化列表 "+res.data["uuidl"].length
					this.mqtterrl = res.data["mqtterrl"]
					this.dyl = res.data["dyl"]

                    var cbt = JSON.parse("{}")
                    console.log(cbt);
                    this.hmjlcbnum = 0;
                    for (var hm1  in res.data["hmjlcb_list"]) {
                        cbt[hm1] = res.data["hmjlcb_list"][hm1].length;
                        this.hmjlcbnum += res.data["hmjlcb_list"][hm1].length;
                    }
                    console.log(cbt);
                    this.hmjlcbl = cbt;

                    var hyt = JSON.parse("{}")
                    this.hmjlhynum = 0;
                    for (var hm2  in res.data["hmjlhy_list"]) {
                        hyt[hm2] = res.data["hmjlhy_list"][hm2].length;
                        this.hmjlhynum += res.data["hmjlhy_list"][hm2].length;
                    }
                    this.hmjlhyl = hyt;

                    var myt = JSON.parse("{}")
                    this.hmjlmynum = 0;
                    for (var hm3  in res.data["hmjlmy_list"]) {
                        myt[hm3] = res.data["hmjlmy_list"][hm3].length;
                        this.hmjlmynum += res.data["hmjlmy_list"][hm3].length;
                    }
                    this.hmjlmyl = myt;

                    var jkt = JSON.parse("{}")
                    this.hmjljknum = 0;
                    for (var hm4  in res.data["hmjljk_list"]) {
                        jkt[hm4] = res.data["hmjljk_list"][hm4].length;
                        this.hmjljknum += res.data["hmjljk_list"][hm4].length;
                    }
                    this.hmjljkl = jkt;



					this.unknow = res.data["unkonw"]
					this.reftime = res.data["time"]
					this.allnum = res.data["offline"] + res.data["online"]
				}else if (res.data["ret"] === -99) {
					localStorage.removeItem('tk');
					this.$router.push("/login")
				}else{
					ElMessage({
					showClose: true,
					message: '数据刷新出错',
					type: 'error',
					})
				}
			}).catch(error => {
				console.log(error);
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			});
		},
	}
});



