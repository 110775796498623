
import axios from 'axios';
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus'
export default defineComponent({
	name: 'LoginView',
	data(){
		return{
			un:"",
			pw:""
		}
	},
	methods:{
		login(){
			console.log(this.un)
			axios.post("/api/login",{"un":this.un,"pw":this.pw}).then((res) => {
				if(res.data['ret'] === 0){
					localStorage.setItem("tk",res.data['tk'])
					this.$router.push("/")
				}else{
					ElMessage({
						showClose: true,
						message: '用户名密码错误',
						type: 'error',
					})
					localStorage.removeItem("tk")
				}
			}).catch(error => {
				ElMessage({
					showClose: true,
					message: '服务器内部错误',
					type: 'error',
				})
			})
		}
	}
});
