
import { defineComponent } from 'vue';
import DlNode from '@/components/DlNode.vue'; // @ is an alias to /src
//import DlShell from '@/components/DlShell.vue'; // @ is an alias to /src
import { Edit, Share, Delete, Search, Upload } from '@element-plus/icons-vue'


export default defineComponent({
  name: 'DlNodeView',
	data(){
		return{
			myfilter:"",
			nf:"",
			agl:false,
			shell:false,
			src:"/api/shell",
			shelllist:[
	{
    title: 'Tab 1',
    name: '1',
    content: 'Tab 1 content',
  },
  {
    title: 'Tab 2',
    name: '2',
    content: 'Tab 2 content',
  },
			],
		}
	},
  components: {
    DlNode,
 //   DlShell,
  },
	methods:{
		agok(e: any){
			console.log("ag ok " + e)
			this.agl = false
		},
		childClick(e: any){
			console.log('from son：',e)
			this.shell = true
			this.src = "/api/shell?123"
		},
		mysearch(){
			this.nf = this.myfilter
		},
		myag(){
			this.agl = true
			console.log('to ',this.agl)
		},
		gotable(){
			this.shell = false
		}
	}
});
