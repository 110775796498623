
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DlHead',
  data(){
    return{
      activeIndex:"",
    }
  },
  methods:{
    handleSelect(key: string, keyPath: string[]){
			//this.$router.push("/node?"+key)
      console.log(key, keyPath[0])
			this.$router.push({name:keyPath[0],params: { userId: key }})
    } 
  }
})
